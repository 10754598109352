import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(private)": [~6,[2],[3]],
		"/(private)/admin": [~7,[2],[3]],
		"/(private)/assets/images": [~8,[2],[3]],
		"/(private)/assets/uploads": [~9,[2],[3]],
		"/(private)/dir/[...slug]": [~10,[2],[3]],
		"/(private)/file/attributes/[...slug]": [12,[2,4],[3]],
		"/(private)/file/block-editor/[...slug]": [~13,[2,4],[3]],
		"/(private)/file/editor/[...slug]": [14,[2,4],[3]],
		"/(private)/file/product/[...slug]": [15,[2,4],[3]],
		"/(private)/file/[...slug]": [~11,[2,4],[3]],
		"/forget": [~27],
		"/(public)/forgot-password": [23,[5]],
		"/(public)/login": [24,[5]],
		"/(private)/orders": [~16,[2],[3]],
		"/(private)/orders/history": [~18,[2],[3]],
		"/(private)/orders/[...slug]": [~17,[2],[3]],
		"/(private)/products": [~19,[2],[3]],
		"/(public)/register": [25,[5]],
		"/(public)/reset-password": [26,[5]],
		"/(private)/sitemap": [~20,[2],[3]],
		"/(private)/stats": [21,[2],[3]],
		"/(private)/workflows": [~22,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';